export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "Nuclear Nightmare",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://www.youtube.com/embed/AfKBinpM2HA?si=IjMidsaaC6gtpsz6",
    domain: "nuclearnightmare.org",
    gaId: "G-Z849XLN3HH",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
